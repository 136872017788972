<template>
    <ul class="nav-pills nav-sidebar flex-column ml-2" style="width: 100%; font-size: 0.95rem; list-style-type: none">
        <li class="m-2 mt-0 pt-0" style="color: orange">Dashboard</li>
        <li class="nav-item">
            <router-link to="/dashboard" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-odometer"></i>
                    <div class="ml-2">Dashboard</div>
                </div>
            </router-link>
        </li>
        <li class="nav-item" v-if="rolesInfo.includes('OWNED') || rolesInfo.includes('QA')">
            <router-link to="/dashboard2" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-pie-chart"></i>
                    <div class="ml-2">Statistics</div>
                </div>
            </router-link>
        </li>
        <li class="nav-item" v-if="$primeFeature && (rolesInfo.includes('OWNED') || rolesInfo.includes('QA'))">
            <router-link to="/prime-user-statistics" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-data-line"></i>
                    <div class="ml-2">Prime user statistics</div>
                </div>
            </router-link>
        </li>
        <li class="nav-item" v-if="$primeFeature && (rolesInfo.includes('OWNED') || rolesInfo.includes('QA'))">
            <router-link to="/prime-recharge-report" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-data-analysis"></i>
                    <div class="ml-2">Prime Recharge Report</div>
                </div>
            </router-link>
        </li>
        <li class="nav-item has-treeview">
            <a href="javascript:void(0);" class="nav-link" onclick="toogleMenu(this);"
                v-if="userInfo.roleId != 7 && userInfo.roleId != 18">
                <div class="d-flex w-100">
                    <div style="color: #ffffff" class="flex-fill d-flex flex-row align-items-center">
                        <i class="el-icon-more"></i>
                        <div class="ml-2 d-flex w-100">
                            <div class="flex-fill">More..</div>
                        </div>
                    </div>
                    <div><i class="fas fa-angle-left right"></i></div>
                </div>
            </a>
            <ul class="nav nav-treeview" style="display: none">
                <li class="nav-item" v-if="userInfo.roleId != 7">
                    <router-link to="/staff-ranking" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-trophy"></i>
                            <div class="ml-2">Staff Ranking</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item" v-if="userInfo.roleId != 7">
                    <router-link to="/technical-shift" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-watch"></i>
                            <div class="ml-2">Technical shift</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item"
                    v-if="userInfo.roleId != 7 && (rolesInfo.includes('OWNED') || rolesInfo.includes('QA'))">
                    <router-link to="/staff-check-in" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-time"></i>
                            <div class="ml-2">Staff Check-in</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item" v-if="$licenseFeature">
                    <!-- v-if="userInfo.roleId != 7 && (rolesInfo.includes('OWNED') || rolesInfo.includes('QA') || userInfo.userEmail.toLowerCase() === 'thuongqth@irender.vn' || userInfo.userEmail.toLowerCase() === 'yendph@irender.vn')" -->
                    <router-link to="/software-license" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-postcard"></i>
                            <div class="ml-2">Software license</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item"
                    v-if="userInfo.roleId != 7 && (rolesInfo.includes('OWNED') || rolesInfo.includes('QA'))">
                    <router-link to="/user-active-view" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-check"></i>
                            <div class="ml-2">User active</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item"
                    v-if="userInfo.roleId != 7 && (rolesInfo.includes('OWNED') || rolesInfo.includes('QA'))">
                    <router-link to="/job-sync" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-refresh"></i>
                            <div class="ml-2">Job sync</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item"
                    v-if="userInfo.roleId != 7 && (rolesInfo.includes('OWNED') || rolesInfo.includes('QA'))">
                    <router-link to="/gpuhub-sync-view" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <!--<i class="el-icon-refresh"></i>-->
                            <img src="/static/favicon/512Favicon123-2.png" class="d-inline" style="width: 1rem;" />
                            <div class="ml-2">User tranfering data </div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item"
                    v-if="userInfo.roleId != 7 && (rolesInfo.includes('OWNED') || rolesInfo.includes('QA'))">
                    <router-link to="/export-task" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-document"></i>
                            <div class="ml-2">Export data</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item"
                    v-if="checkAccess('USER_VIEW') || rolesInfo.includes('OWNED') || rolesInfo.includes('QA')">
                    <router-link to="/rental-news" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-news"></i>
                            <div class="ml-2">Rental News </div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item" v-if="[1, 2, 5, 6].includes(userInfo.roleId) || checkAccess('COUPON_VIEW') || rolesInfo.includes('OWNED') || rolesInfo.includes('QA')">
                    <router-link to="/email-notification" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-message"></i>
                            <div class="ml-2">Email notification</div>
                        </div>
                    </router-link>
                </li>
                <!-- <li class="nav-item"
                    v-if="checkAccess('COUPON_VIEW') || rolesInfo.includes('OWNED') || rolesInfo.includes('QA')">
                    <router-link to="/promotion-mngt" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="fas fa-percent"></i>
                            <div class="ml-2">Promotion Management</div>
                        </div>
                    </router-link>
                </li> -->
                <li class="nav-item"
                    v-if="[1, 2, 5, 6].includes(userInfo.roleId) || checkAccess('COUPON_VIEW') || rolesInfo.includes('OWNED') || rolesInfo.includes('QA')">
                    <router-link to="/banner-mngt" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="far fa-images"></i>
                            <div class="ml-2">Banner Management</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item" v-if="rolesInfo.includes('OWNED') || rolesInfo.includes('QA')">
                    <router-link to="/chat-conversation" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-chat-line-round"></i>
                            <div class="ml-2">Chat conversations </div>
                        </div>
                    </router-link>
                </li>
            </ul>
        </li>

        <li class="m-2 mt-3" style="color: orange" v-if="checkAccess('USER_VIEW')">User manager</li>
        <li class="nav-item" v-if="checkAccess('USER_VIEW')">
            <router-link to="/users" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-user"></i>
                    <div class="ml-2">Users</div>
                </div>
            </router-link>
        </li>
        <li class="nav-item" v-if="checkAccess('USER_VIEW')">
            <router-link to="/users-history" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-date"></i>
                    <div class="ml-2">Users history</div>
                </div>
            </router-link>
        </li>
        <li class="nav-item" v-if="checkAccess('USER_VIEW')">
            <router-link to="/rating" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-star-off"></i>
                    <div class="ml-2">Rating</div>
                </div>
            </router-link>
        </li>
        <li class="nav-item">
            <router-link to="/gpuhub-sync-abandoned" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="fas fa-recycle"></i>
                    <div class="ml-2">Z Storage Cleaning</div>
                </div>
            </router-link>
        </li>
        <li class="nav-item has-treeview"
            v-if="checkAccess('USER_VIEW') || checkAccess('USERREQUEST__VIEW') || checkAccess('EXTRA_SERVICE_VIEW')">
            <a href="javascript:void(0);" class="nav-link" onclick="toogleMenu(this);">
                <div class="d-flex w-100">
                    <div style="color: #ffffff" class="flex-fill d-flex flex-row align-items-center">
                        <i class="el-icon-more"></i>
                        <div class="ml-2 d-flex w-100">
                            <div class="flex-fill">More..</div>
                            <div v-if="checkAccess('EXTRA_SERVICE_VIEW')">
                                <span class="badge badge-warning ml-1 mr-2">
                                    {{ extraServiceRequestCount.countWaitingRequest }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div><i class="fas fa-angle-left right"></i></div>
                </div>
            </a>
            <ul class="nav nav-treeview" style="display: none">
                <li class="nav-item" v-if="checkAccess('USERREQUEST__VIEW')">
                    <router-link to="/request-from-user" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-position"></i>
                            <div class="ml-2">Request from user</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item" v-if="checkAccess('EXTRA_SERVICE_VIEW')">
                    <router-link to="/extra-service-request" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-position"></i>
                            <div class="ml-2 d-flex w-100">
                                <div class="flex-fill">Extra service request</div>
                                <div>
                                    <span class="badge badge-danger ml-1 right">
                                        {{ extraServiceRequestCount.countWaitingRequest }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/paid-users" class="nav-link pt-1 pb-1">
                        <div class="d-flex flex-row align-items-center">
                            <i class="el-icon-money"></i>
                            <div class="ml-2">Paid users</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/users-idle" class="nav-link pt-1 pb-1">
                        <div class="d-flex flex-row align-items-center">
                            <i class="el-icon-coffee-cup"></i>
                            <div class="ml-2">User idle</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/blacklist-users" class="nav-link pt-1 pb-1">
                        <div class="d-flex flex-row align-items-center">
                            <i class="el-icon-close"></i>
                            <div class="ml-2">Blacklist users</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/users-top-most-used" class="nav-link pt-1 pb-1">
                        <div class="d-flex flex-row align-items-center">
                            <i class="el-icon-star-off"></i>
                            <div class="ml-2">Top most use</div>
                        </div>
                    </router-link>
                </li>
                <!--<li class="nav-item">
                <router-link to="/parsec-user"
                             class="nav-link pt-1 pb-1">
                    <div class="d-flex flex-row align-items-center">
                        <i class="el-icon-connection"></i>
                        <div class="ml-2">Parsec user</div>
                    </div>
                </router-link>
            </li>-->
            </ul>
        </li>
        <li v-if="checkAccess('MACHINE_VIEW')" class="m-2 mt-3" style="color: orange">Machine manager</li>
        <li class="nav-item" v-if="checkAccess('MACHINE_VIEW')">
            <router-link to="/gpuhub-machines" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-monitor"></i>
                    <div class="ml-2">Machine</div>
                </div>
            </router-link>
        </li>
        <!-- <li class="nav-item" v-if="userInfo.roleId != 7 && rolesInfo.includes('OWNED')">
            <router-link to="/b2b-machines" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-monitor"></i>
                    <div class="ml-2">B2C Machine</div>
                </div>
            </router-link>
        </li> -->
        <!-- <li class="nav-item" v-if="userInfo.roleId != 7 && rolesInfo.includes('OWNED')">
            <router-link to="/gpuhub-machines-fpt" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-monitor"></i>
                    <div class="ml-2">FPT Machine</div>
                </div>
            </router-link>
        </li> -->
        <li v-if="$fixedRentalFeature && checkAccess($functionalCodeWebadmin.FIXED_RENTAL_VIEW)" class="nav-item">
            <router-link to="/fixed-rental" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-date"></i>
                    <div class="ml-2">Fixed rental</div>
                </div>
            </router-link>
        </li>
        <li class="nav-item" v-if="checkAccess('RDP_GATEWAY_VIEW')">
            <router-link to="/rdp-gateway-history" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-guide"></i>
                    <div class="ml-2">Remote Desktop Gateway</div>
                </div>
            </router-link>
        </li>
        <li class="nav-item" v-if="userInfo.roleId != 7 && rolesInfo.includes('OWNED') || rolesInfo.includes('QA')">
            <router-link to="/gpuhub-servers" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-cpu"></i>
                    <div class="ml-2">Server management</div>
                </div>
            </router-link>
        </li>
        <li class="nav-item" v-if="checkAccess('MACHINE_VIEW')">
            <router-link to="gpuhub-machines-abandoned" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-close"></i>
                    <div class="ml-2">Machine abandoned</div>
                </div>
            </router-link>
        </li>
        <li v-if="checkAccess('TRANSACTION_VIEW')" class="m-2 mt-3" style="color: orange">Payment & Invoice</li>
        <li v-if="checkAccess('TRANSACTION_VIEW')" class="nav-item">
            <router-link to="/gpuhub-transactions" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-wallet"></i>
                    <div class="ml-2">Transaction</div>
                </div>
            </router-link>
        </li>
        <li v-if="checkAccess('GPUHUBBILLING_VIEW')" class="nav-item">
            <router-link to="/gpuhub-billing" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-document"></i>
                    <div class="ml-2">Billing</div>
                </div>
            </router-link>
        </li>
        <li v-if="checkAccess('GPUHUBINVOICE_VIEW') || checkAccess('COUPON_VIEW') || checkAccess('PAYMENT_GATEWAY_HISTORY_VIEW')"
            class="nav-item has-treeview">
            <a href="javascript:void(0);" class="nav-link" onclick="toogleMenu(this);">
                <div class="d-flex w-100">
                    <div style="color: #ffffff" class="flex-fill d-flex flex-row align-items-center">
                        <i class="el-icon-more"></i>
                        <div class="ml-2 d-flex w-100">
                            <div class="flex-fill">More..</div>
                        </div>
                    </div>
                    <div><i class="fas fa-angle-left right"></i></div>
                </div>
            </a>
            <ul class="nav nav-treeview" style="display: none"
                v-if="checkAccess('GPUHUBINVOICE_VIEW') || checkAccess('PAYMENT_GATEWAY_HISTORY_VIEW') || checkAccess('COUPON_VIEW')">
                <li class="nav-item" v-if="checkAccess('GPUHUBINVOICE_VIEW')">
                    <router-link to="/gpuhub-invoices" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-tickets"></i>
                            <div class="ml-2">Invoice</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item" v-if="checkAccess($functionalCodeWebadmin.PAYMENT_GATEWAY_HISTORY_VIEW)">
                    <router-link to="/stripe-history" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-date"></i>
                            <div class="ml-2">Stripe history</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item" v-if="checkAccess('COUPON_VIEW')">
                    <router-link to="/gpuhub-promotion" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-present"></i>
                            <div class="ml-2">Gift code & Coupon</div>
                        </div>
                    </router-link>
                </li>
            </ul>
        </li>

        <li v-if="checkAccess('SYSTEM_MANAGER_VIEW')" class="m-2 mt-3" style="color: orange">Admin manager</li>
        <li v-if="checkAccess($functionalCodeWebadmin.SYSTEM_MANAGER_VIEW) && [1, 2, 5, 6].includes(userInfo.roleId)"
            class="nav-item">
            <router-link to="/system-manager" class="nav-link pt-1 pb-1">
                <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                    <i class="el-icon-setting"></i>
                    <div class="ml-2">System manager</div>
                </div>
            </router-link>
        </li>
        <li v-if="checkAccess('SYSTEM_MANAGER_VIEW')" class="nav-item has-treeview">
            <a href="javascript:void(0);" class="nav-link" onclick="toogleMenu(this);">
                <div class="d-flex w-100">
                    <div style="color: #ffffff" class="flex-fill d-flex flex-row align-items-center">
                        <i class="el-icon-more"></i>
                        <div class="ml-2 d-flex w-100">
                            <div class="flex-fill">More..</div>
                        </div>
                    </div>
                    <div><i class="fas fa-angle-left right"></i></div>
                </div>
            </a>
            <ul class="nav nav-treeview" style="display: none">
                <li v-if="checkAccess('SYSTEM_MANAGER_VIEW')" class="nav-item">
                    <router-link to="/staff-history" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-date"></i>
                            <div class="ml-2">Staff history</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item" v-if="[1, 2, 5, 6].includes(userInfo.roleId)">
                    <router-link to="/admin-department" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-postcard"></i>
                            <div class="ml-2">Departments</div>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item" v-if="[1, 2, 3, 5, 6].includes(userInfo.roleId)">
                    <router-link to="/staff" class="nav-link pt-1 pb-1">
                        <div style="color: #ffffff" class="d-flex flex-row align-items-center">
                            <i class="el-icon-postcard"></i>
                            <div class="ml-2">Staff</div>
                        </div>
                    </router-link>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
import { mapState } from "vuex";
import baseComponent from "@/scripts/baseComponent";
export default {
    extends: baseComponent,
    computed: {
        ...mapState({
            userInfo: (state) => state.auth.user,
            rolesInfo: (state) => state.auth.roles,
            listServicePack: (state) => state.common.listServicePack,
            availabilityServiceList: (state) => state.common.availabilityServiceList,
            availabilityServiceAIList: (state) => state.common.availabilityServiceAIList,
            decentralization: (state) => state.auth.decentralization,
            extraServiceRequestCount: (state) => state.extraServiceRequestCount,
            calendarData: (state) => state.common.technicalShift,
            currentCalendarIndex: (state) => parseInt(state.common.calendarIndex),
            currentDayOfWeek: (state) => parseInt(state.common.currentDayOfWeek),
        }),
    }
}
</script>
